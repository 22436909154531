import { navigate } from 'gatsby';
import React from 'react';

const Contact = () => {
  const handleClick = (ev, link) => {
    ev.preventDefault();
    navigate(link);
  };
  return (
    <div className="bg-blue-500 px-2 py-8 md:px-3 lg:px-5">
      <div className="flex flex-wrap justify-content-center flex-wrap">
        <div className="flex justify-content-center md:mb-0 mb-3" style={{ flex: '1' }}>
          <img src="/white-logo.png" alt="logo" style={{ height: '200px', width: '250px' }} id="contact" />
        </div>
        <div className="flex align-items-center lg:align-items-start text-left column" style={{ flex: '1', minWidth: '300px' }}>
          <div className="text-white text-lg mb-4 flex flex-wrap" style={{ maxWidth: '290px' }}> <i className="pi pi-map-marker border-round mr-1" style={{ marginTop: '20px' }} /> 2380 Wycliff St Suite 200 <br /> St Paul, MN 55114</div>
          <div className="text-white mb-3 text-left"><i className="pi pi-phone border-round p-1 mr-2" />+1 952 393 0995</div>
          <div className="text-white mb-3 text-left"><i className="pi pi-inbox border-round p-1 mr-2" />Info@midwayhomesadultcare.com</div>
        </div>
        <div className="flex align-items-center lg:align-items-start column" style={{ flex: '1', minWidth: '300px' }}>
          <div className="text-900 text-white font-bold line-height-3 mb-3">Midway Homes Adult Care</div>
          <a onClick={(ev) => handleClick(ev, '/')} className="line-height-3 block cursor-pointer text-white mb-2">Home</a>
          <a onClick={(ev) => handleClick(ev, '/about')} className="line-height-3 block cursor-pointer text-white mb-2">About</a>
          <a onClick={(ev) => handleClick(ev, '/ics')} className="line-height-3 block cursor-pointer text-white mb-2">ICS</a>
          <a onClick={(ev) => handleClick(ev, '/rcs')} className="line-height-3 block cursor-pointer text-white mb-2">RCS</a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
