import React from 'react';

const PageHeader = ({ title }) => {
  return (
    <div>
      <div className="bg-cover no-repeat" style={{ height: '20vh', backgroundPosition: 'center center', background: 'linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url("/bg2-min.jpg")' }}>
        <div className="text-900 h-100 text-3xl text-white flex align-items-center justify-content-center font-medium mb-3">
          <span className="page-header-title animate__animated animate__zoomIn animate__delay-1s">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
