import React from 'react';
import Contact from '../components/contact/Contact';
import Layout from '../components/layout';
import Seo from '../components/seo';
import PageHeader from '../components/services/service-page/PageHeader';
import RCSContent from '../components/services/service-page/RCSContent';

const RCSPage = () => {
  return (
    <Layout>
      <Seo title="RCS" />
      <PageHeader title="Adult Residential Crisis Stabilization Services (RCS)" />
      <RCSContent />
      <Contact />
    </Layout>
  );
};

export default RCSPage;

export const Head = () => (
  <Seo title="RCS" />
);
