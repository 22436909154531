import React from 'react';

const RCSContent = () => {
  return (
    <div className="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        Crisis stabilization services are individualized mental health services
        provided to a Minnesota Health Care Programs (MHCP) member following a
        crisis assessment and crisis intervention. The service is designed to
        restore the member to a pre-crisis level of functioning. Crisis
        stabilization services are provided in a residential setting for those
        who are in need of structure and assistance from 24-hour mental health
        staff and are at risk of hospitalization if they do not receive these
        services.
      </div>

      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        Midway Homes Adult Care is a residential crisis stabilization (RCS)
        provider regulated through the Minnesota Department of Human Services
        (DHS). This program integrates mental health, medical, and substance use
        care in an inpatient, 24-hour, supervised setting for up to 10 days.
        These services help individuals who are experiencing a mental health
        crisis or emergency.
      </div>
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        We serve adults 18 years of age or older experiencing a mental health
        crisis. Services are billed to Medical Assistance or a private health
        insurance plan. If your coverage does not include residential crisis
        services, the cost of treatment may be covered by your county of
        residence
      </div>

      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        Midway Homes Adult Care is dedicated to helping individuals set goals
        toward independence and lifelong wellness with customized treatment. We
        help individuals manage their immediate mental health needs, develop a
        crisis prevention plan, learn new coping skills, and recognize symptoms
        earlier. An important goal of crisis stabilization services is the
        development of a discharge plan, including referrals to other People
        Incorporated services to meet individuals where they are at in their
        recovery journey.
      </div>
      <div className="line-height-3 text-lg text-700 mb-4 animate__animated animate__bounceInUp animate__delay-1s">
        A crucial objective of our crisis stabilization services is to develop a
        comprehensive discharge plan that considers each individual's unique
        circumstances. This plan includes referrals to other People Incorporated
        services, ensuring individuals receive the appropriate support to meet
        them at their current stage in their recovery journey. Our ultimate aim
        is to empower individuals to regain stability, thrive in their daily
        lives, and achieve their long-term wellness goals.
      </div>
    </div>
  );
};

export default RCSContent;
